<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state">
        <template v-if="required" #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <template v-if="desc">
            <small v-if="desc" class="form-text text-muted">{{desc}}</small>
        </template>
        <div class="text-center">
            <div class="input-field">
                <div class="helper" v-if="loading">
                    <b-spinner v-if="loading" small></b-spinner>
                </div>
                <div class="helper" v-else-if="!files_selected" @click="show_modal = true">{{placeholder}}</div>
                <div class="preview" v-else-if="files_selected">
                    <template v-if="multiple">
                        <div class="attachments-browser" style="height: auto;overflow: auto">
                            <ul class="attachments ui-sortable ui-sortable-disabled">
                                <li v-for="(file, i) in selected_files" :key="i" class="attachment save-ready">
                                    <div class="attachment-preview js--select-attachment type-image subtype-jpeg portrait">
                                        <label class="thumbnail">
                                            <div class="centered">
                                                <img :src="file.url" />
                                            </div>
                                        </label>
                                    </div>
                                    <button type="button" tabindex="-1" style="display: block; border-radius: 50%;" class="check" @click.prevent="clearFiles(i)" >
                                        <span class="media-modal-icon" style="background-position: -100px 0;"></span>
                                    </button>
                                </li>
                                <li class="attachment save-ready" @click="show_modal = true">
                                    <div class="attachment-preview js--select-attachment type-image subtype-jpeg portrait">
                                        <label class="thumbnail" style="border: 1px dotted #eee;">
                                            <div class="centered">
                                                <i class="fa fa-images" style="margin-top: 0px;" ></i>
                                                <div class="file-name">{{placeholder}}</div>
                                            </div>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-if="!multiple">
                        <div @click="show_modal = true">
                            <span class="preview" v-if="selected_files && ['doc','docx'].includes(selected_files.extension)">
                                <i class="far fa-file-word"></i>
                                {{selected_files.name}}
                            </span>
                            <span class="preview" v-else-if="selected_files && ['xlsx'].includes(selected_files.extension)">
                                <i class="far fa-file-excel"></i>
                                {{selected_files.name}}
                            </span>
                            <span class="preview" v-else-if="selected_files && ['pdf'].includes(selected_files.extension)">
                                <i class="fa fa-file-word-o"></i>
                                {{selected_files.name}}
                            </span>
                            <span class="preview" v-else-if="selected_files && ['csv'].includes(selected_files.extension)">
                                <i class="fa fa-file-csv"></i>
                                {{selected_files.name}}
                            </span>
                            <span class="preview" v-else-if="selected_files && ['zip'].includes(selected_files.extension)">
                                <i class="far fa-file-archive"></i>
                                {{selected_files.name}}
                            </span>
                            <span class="preview" v-else-if="selected_files && ['mp4', 'm4v'].includes(selected_files.extension)">
                                <video height="180" controls>
                                    <source :src="selected_files.url" :type="selected_files.type">
                                    Your browser does not support the video tag.
                                </video>
                            </span>
                            <span class="preview" v-else-if="selected_files && ['mp3'].includes(selected_files.extension)">
                                <audio controls style="width: 100%; margin-top: 30px;">
                                    <source :src="selected_files.url" :type="selected_files.type">
                                </audio>
                                {{selected_files.name}}
                            </span>
                            <span class="preview" v-else>
                                <img v-if="selected_files" :src="selected_files.url" :alt="selected_files.name" style="width: 100%" />
                            </span>
                        </div>
                    </template>
                </div>
            </div>

            <a href="javascript:;" class="btn btn-danger btn-sm mt-1" @click.prevent="clearFiles(null)" v-if="files_selected && !multiple">
                <i class="fa fa-trash-alt" ></i> Huỷ chọn
            </a> 

            <file-select :showModal="show_modal" @close="close" @selected="selectFiles" :multiple="multiple" :server="server" :accept='accept' />
        </div>
    </b-form-group>
</template>

<script>
    import FileSelect from './FileSelect';
    export default {
        components: {
            FileSelect,
        },
        props: {
            label: String,
            multiple: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: "Chọn hình ảnh"
            },
            model: Object,
            desc: String,
            attribute: String,
            accept: String,
            required: Boolean,
            errors: {},
        },
        data() {
            return {
                server: {
                    home: 'media',
                    add_folder: 'media/add-folder',
                    file_uploads: 'media/upload',
                    update: 'media/update'
                },
                show_modal: false,
                selected_files: null,
                files_selected: false,
                state: null,
                errorTxt: '',
                changeTrigger: true,
                loading: false,
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute] && val[this.attribute] !== null) {
                        if (this.changeTrigger) {
                            this.loadSelected();
                        }
                        this.changeTrigger = true;
                    } else {
                        this.selected_files = [];
                        this.files_selected = false;
                    }
                }, deep: true
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            selectFiles(files) {
                if (this.multiple) {
                    if (!this.selected_files) {
                        this.selected_files = [];
                    }
                    for (let file of files) {
                        if (!this.selected_files.find(f => f.id === file.id)) {
                            this.selected_files.push(file);
                        }
                    }
                    this.$emit('input', this.selected_files);
                    this.files_selected = true;
                    this.show_modal = false;
                    this.model[this.attribute] = this.selected_files.map(f => f.id);
                } else {
                    this.selected_files = files;
                    this.$emit('input', this.selected_files);
                    this.files_selected = true;
                    this.show_modal = false;
                    this.model[this.attribute] = this.selected_files.id;
                }
            },
            clearFiles(index) {
                if (index != null) {
                    this.selected_files.splice(index, 1);
                } else {
                    this.selected_files = [];
                }
                this.files_selected = this.selected_files.length > 0;
                this.$emit('input', this.selected_files);
                if (this.multiple) {
                    this.model[this.attribute] = this.selected_files.map(f => f.id);
                } else {
                    this.model[this.attribute] = null;
                }
            },
            loadSelected() {
                if (this.model[this.attribute]) {
                    let ids;
                    if (this.multiple) {
                        ids = this.model[this.attribute].join(',');
                    } else {
                        ids = this.model[this.attribute];
                    }
                    if (ids) {
                        this.loading = true;
                        this.$service.get(`${this.server.home}/gets`, {
                            params: {ids: ids}
                        }).then(response => {
                            if (this.multiple) {
                                this.selected_files = response.data;
                            } else if (response.data.length) {
                                this.selected_files = response.data[0];
                            }
                            this.files_selected = true;
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
                }
            },
            close() {
                this.show_modal = false;
            }
        },
        mounted() {
            this.loadSelected();
        }
    }
</script>

<style lang="scss" scoped>
    .input-field {
        width: 100%;
        background: #eee;
        min-height: 150px;
        cursor: pointer;
        padding: 0;
    }
    .input-field .helper {
        text-align: center;
        padding-top: 4.3rem;
        padding-bottom: 4em;
        border: 2px dashed #ddd;
        color: #a5a5a5;
    }
    .preview {
        .image:first-child {
            margin-left: 0px;
        }
        .image {
            display: inline-block;
            padding: 0px;
            margin-left: 5px;
        }
        .image img {
            width: 100%;
            max-width: 200px;
        }
        i {
            margin-top: 40px;
            font-size: 44px;
        }
    }
</style>