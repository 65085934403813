<template>
    <div>
        <g-loading v-if="firstLoading" />
        <g-table v-else :filter="filterFormModel" :isSelected="false" :itemCount="itemCount" :items="items" :fields="fields" 
                 @load="load" @reset="reset" :isFooter="false" title="Tham số hệ thống" titleIcon="fa fa-clipboard-list">
            <template #cell(value)="{item}">
                <span v-if="item.value && item.type === 'TEXT'" >
                    {{ item.value}}
                </span>
                <span v-else-if="item.value && ['TEXTAREA', 'VIDEO'].includes(item.type)" >
                    {{ item.value ? 'Đã cập nhật': ''}}
                </span>
                <span v-else-if="item.value && item.type === 'SINGLE_CHOICE'" >
                    <span v-if='item.options.find(o => o.value === item.value)'>{{ item.options.find(o => o.value === item.value).text}}</span>
                    <span v-else>{{item.value}}</span>
                </span>
                <span v-else-if="item.value && item.type === 'MULTI_CHOICE'" >
                    <span v-for="(value, index) in item.value" :key="index">
                        <span v-if='item.options.find(o => o.value === value)'>
                            {{ item.options.find(o => o.value === value).text}}
                            <span v-if='index < item.value.length - 1'>, </span>
                        </span>
                        <span v-else>{{item.value}}</span>
                    </span>
                </span>
                <span v-else class="small text-gray-999" >Chưa cập nhật</span>
            </template>
            <template #cell(exam)="{item}">
                <span v-if="item.value && item.view === 'color'" >
                    <i class='fa fa-square' :style='`color: ${item.value}`' ></i>
                </span>
                <span v-else-if="item.value && item.view === 'img'" >
                    <b-img thumbnail fluid :src="item.value" :alt="item.value" style="max-height: 30px;" />
                </span>
            </template>
            <template #cell(action)="{item}">
                <g-button :loading="updateLoading" size="sm" variant="outline-info" class="ml-1" icon="pencil" @click="item.id ? update(item.id) : create(item)"  />
            </template>
        </g-table>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" no-close-on-backdrop no-enforce-focus>
            <div class="row">
                <div class="col-md-6">
                    <form-input :disabled="true" label="Mã tham số" :model="formModel" :errors="formErrors" attribute="key" />
                </div>
                <div class="col-md-6">
                    <form-input :disabled="true" label="Tham số" :model="formModel" :errors="formErrors" attribute="label" />
                </div>
            </div>
            <form-input v-if="formModel.type === 'TEXT'" label="Giá trị" :model="formModel" :errors="formErrors" attribute="value" />
            <form-textarea v-else-if="formModel.type === 'TEXTAREA'" label="Giá trị" :model="formModel" :errors="formErrors" attribute="value" />
            <form-media v-else-if="formModel.type === 'VIDEO'" label="Giá trị" :model="formModel" :errors="formErrors" attribute="value" placeholder="Chọn video" accept='video/mp4,video/x-m4v' />
            <form-select v-else-if="formModel.type === 'SINGLE_CHOICE'" label="Giá trị" :model="formModel" attribute="value" :errors="formErrors" :options="formModel.options" />
            <form-select-multiple v-else-if="formModel.type === 'MULTI_CHOICE'" label="Giá trị" :model="formModel" attribute="value" errors="formErrors" :options="formModel.options"/>

            <template v-slot:modal-footer="{ ok, cancel}">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import FormMedia from '@/components/media/FormMedia';

    export default {
        mixins: [list, crud],
        components: {FormMedia},
        data: function () {
            return {
                service: '/etc',
                fields: [
                    {key: 'label', label: "Danh mục"},
                    {key: 'value', label: "Giá trị"},
                    {key: 'exam', label: "", class: "text-center"},
                    {key: 'action', label: "Hành động", class: 'text-right'}
                ]
            };
        },
        methods: {
            create: function (defaultFormModel) {
                this.formModel = {};
                this.formModel = Object.assign({value: null}, defaultFormModel);
                this.formTitle = "Cập nhật giá trị";
                this.formErrors = {};
                this.showForm = true;
            },
            beforeLoad() {
                for (let etc of this.$params.etc) {
                    let item = this.items.find(item => item.key === etc.key);
                    if (!item) {
                        this.items.push(etc);
                        this.itemCount += 1;
                    } else {
                        item.label = etc.label;
                        item.options = etc.options;
                        item.type = etc.type;
                        item.view = etc.view ? etc.view : null;
                    }
                }

                let items = [];
                for (let index in this.items) {
                    let item = this.items[index];
                    if (item.type === 'MULTI_CHOICE') {
                        item.value = JSON.parse(item.value);
                    }
                    let etc = this.$params.etc.find(etc => item.key === etc.key);
                    if (!etc) {
                        continue;
                    }
                    items.push(item);
                }
                this.items = items;
            },
            beforeUpdate(data) {
                let etc = this.$params.etc.find(item => item.key === data.key);
                if (etc) {
                    data.label = etc.label;
                    data.options = etc.options;
                    data.type = etc.type;
                }
            }
        }
    }
</script>